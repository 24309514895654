import * as React from "react";

import { StaticQuery, graphql } from "gatsby";

import SpringLink from "./SpringLink";
import { StaticImage } from "gatsby-plugin-image";
import _ from "lodash";

const Navigation = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: {
              fileAbsolutePath: { regex: "/(properties)/" }
              frontmatter: { draft: { ne: true } }
            }
          ) {
            edges {
              node {
                frontmatter {
                  title
                }
                fields {
                  slug
                }
                fileAbsolutePath
              }
            }
          }
        }
      `}
      render={(data) => (
        <header>
          <section
            id="navigation-mobile"
            className="section section-navigation mobile-primary feature"
          >
            <section className="grid-navigation container grid-960">
              <nav className="navbar">
                <section className="navbar-section">
                  <a
                    href="#di-sidebar"
                    className="btn btn-xl btn-link btn-action show-lg"
                    data-turbolinks="false"
                  >
                    <i className="ion-ios-menu"></i>
                  </a>
                  <SpringLink to="/" className="navbar-brand mr-10">
                    <StaticImage
                      src="../images/branding/logo-horizontal.svg"
                      alt="Densa Immobilien AG Logo"
                      loading="eager"
                    />
                  </SpringLink>
                </section>
                <a href="" className="btn hide-lg">
                  Kontakt
                </a>
              </nav>
            </section>
          </section>

          <section id="navigation" className="section section-hero feature">
            <div className="grid-hero container grid-960 text-center">
              <div className="navbar hide-md">
                <section className="navbar-section">
                  <div className="container not padded">
                    <div className="columns col-gapless pb-10">
                      <div className="column col-4 vertical-bottom-aligned">
                        <SpringLink to="/" className="navbar-brand mr-10">
                          <StaticImage
                            src="../images/branding/logo-vertical.svg"
                            className="mt-10"
                            height={80}
                            alt="Densa Immobilien AG Logo"
                            loading="eager"
                            placeholder="tracedSVG"
                            layout="constrained"
                          />
                        </SpringLink>
                      </div>
                      <div className="column col-8">
                        <div className="container">
                          <nav className="columns flex-end mt-10 mb-30">
                            <div className="dropdown dropdown-right">
                              <a
                                className="btn btn-link btn-narrow dropdown-toggle"
                                tabIndex="0"
                              >
                                <i className="ion-ios-share ion-lg pr-5"></i>{" "}
                                Teilen
                              </a>
                              <ul className="menu text-left">
                                <li className="menu-item">
                                  <a
                                    href="https://www.linkedin.com/sharing/share-offsite/?url=densaimmobilien.ch"
                                    data-turbolinks="false"
                                  >
                                    <i className="ion-logo-linkedin ion-lg pr-5"></i>{" "}
                                    LinkedIn
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="whatsapp://send?text=densaimmobilien.ch"
                                    data-turbolinks="false"
                                  >
                                    <i className="ion-logo-whatsapp ion-lg pr-5"></i>{" "}
                                    WhatsApp
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    href="sms://?&body=densaimmobilien.ch"
                                    data-turbolinks="false"
                                  >
                                    <i className="ion-ios-chatbubbles ion-lg pr-5"></i>{" "}
                                    SMS
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <a
                              href="mailto:info@densaimmobilien.ch"
                              className="btn btn-link btn-narrow"
                            >
                              <i className="ion-ios-mail ion-lg pr-5"></i>{" "}
                              Kontakt
                            </a>
                          </nav>

                          <nav className="columns flex-end">
                            <SpringLink
                              to="/"
                              className="btn btn-link btn-link-underline btn-narrow"
                            >
                              Home
                            </SpringLink>

                            <SpringLink
                              to="/ueber-uns"
                              className="btn btn-link btn-link-underline btn-narrow"
                            >
                              Über uns
                            </SpringLink>

                            <div className="dropdown dropdown-right di-navbar-dropdown">
                              <a className="btn dropdown-toggle" tabIndex="0">
                                Liegenschaften{" "}
                                <i className="ion-ios-arrow-down pl-5"></i>
                              </a>
                              <ul className="menu text-left">
                                {_.map(data.allMarkdownRemark.edges, (edge) => {
                                  const { frontmatter, fields } = edge.node;

                                  return (
                                    <li className="menu-item" key={fields.slug}>
                                      <SpringLink to={fields.slug}>
                                        {frontmatter.title}
                                      </SpringLink>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>

          <nav id="di-sidebar" className="di-sidebar column col-12">
            <ul
              className="docs-nav nav"
              itemScope
              itemType="http://www.schema.org/SiteNavigationElement"
            >
              <li className="nav-item" itemProp="name">
                <SpringLink to="/">Home</SpringLink>
              </li>
              <li className="nav-item" itemProp="name">
                <SpringLink to="/ueber-uns">Über uns</SpringLink>
              </li>
            </ul>

            <ul className="docs-nav nav mt-30">
              {_.map(data.allMarkdownRemark.edges, (edge) => {
                const { frontmatter, fields } = edge.node;

                return (
                  <li className="nav-item" key={fields.slug}>
                    <SpringLink to={fields.slug}>
                      {frontmatter.title}
                    </SpringLink>
                  </li>
                );
              })}
            </ul>
          </nav>
          <a
            href="#di-sidebar-close"
            id="di-sidebar-close"
            className="di-navigation-mobile-clear-overlay"
          ></a>
        </header>
      )}
    />
  );
};

export default Navigation;
