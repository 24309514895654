import React from "react";
import TransitionLink from "gatsby-plugin-transition-link";

const SpringLink = ({ to, children, ...rest }) => (
  <TransitionLink
    to={to}
    exit={{ length: 0.3, state: { y: -100, opacity: 0 } }}
    entry={{
      delay: 0.3,
      length: 0.3,
      state: { y: 100, opacity: 0 },
    }}
    {...rest}
  >
    {children}
  </TransitionLink>
);

export default SpringLink;
