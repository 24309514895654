import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import React, { createContext, createRef, useContext, useState } from "react";

import { DefaultLayout } from "react-photoswipe-gallery";

const PhotoswipeContext = createContext(null);

const PhotoswipeContextProvider = ({ children }) => {
  const [photoswipeLayoutRef] = useState(createRef());

  return (
    <PhotoswipeContext.Provider value={{ photoswipeLayoutRef }}>
      {children}

      <DefaultLayout
        shareButton={false}
        fullscreenButton={false}
        zoomButton={true}
        ref={photoswipeLayoutRef}
      />
    </PhotoswipeContext.Provider>
  );
};

const usePhotoswipeContext = () => useContext(PhotoswipeContext);

export { PhotoswipeContextProvider, usePhotoswipeContext };
