import Navigation from "./Navigation";
import { PhotoswipeContextProvider } from "../utils/contexts/photoswipeContext";
import React from "react";

const Layout = ({ children }) => {
  return (
    <PhotoswipeContextProvider>
      <Navigation />

      {children}
    </PhotoswipeContextProvider>
  );
};

export default Layout;
